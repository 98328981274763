// dependencies
import React from "react";
import {
  View,
  TouchableWithoutFeedback,
  StyleSheet,
  ViewStyle,
  AccessibilityRole,
  GestureResponderEvent,
} from "react-native";

// components
import Text from "../Text";

// libraries
import { noop } from "@gdf/shared/src/libraries";
import { emToPx } from "../../libraries";

// hocs
import withHover, { WithHoverInjectedPropsType } from "../../hocs/withHover";

type IProps = {
  result: any;
  label: string;
  depth: number;
  highlighted: boolean;
  onPress: (event: GestureResponderEvent) => void;
} & WithHoverInjectedPropsType;

type IState = {};

class Result extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps>;

  private $nodes = {
    result: React.createRef<TouchableWithoutFeedback>(),
  };

  private getStyles(): StyleSheet.NamedStyles<{
    result: ViewStyle;
    result__highlighted: ViewStyle;
  }> {
    const { depth } = this.props;

    return StyleSheet.create({
      result: {
        paddingTop: emToPx(0.5),
        paddingRight: emToPx(0.5),
        paddingBottom: emToPx(0.5),
        paddingLeft: emToPx((1 + depth) * 0.5),
      },
      result__highlighted: {
        backgroundColor: "rgba(0, 0, 0, .05)",
      },
    });
  }

  public render(): JSX.Element {
    const { result, label, highlighted, onPress, ...attrs } = this.props;

    const styles = this.getStyles();

    return (
      <TouchableWithoutFeedback
        ref={this.$nodes.result}
        accessibilityRole={"option" as AccessibilityRole}
        accessible={false}
        aria-selected={highlighted}
        onPress={onPress}
      >
        <View
          {...attrs}
          style={[styles.result, highlighted && styles.result__highlighted]}
        >
          <Text>{label}</Text>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

Result.defaultProps = {
  depth: 0,
  onPress: noop,
};

export default withHover(Result);
