// dependencies
import React, { useState } from "react";

// libraries
import createHoverMonitor from "../libraries/utils/createHoverMonitor";
import { noop } from "@gdf/shared/src/libraries";

const hover = createHoverMonitor();

export type WithHoverInjectedPropsType = {
  hovered: boolean;
  onMouseEnter: Function;
  onMouseLeave: Function;
};

type WithHoverWrappedProps = {
  onHoverIn?: () => void;
  onHoverOut?: () => void;
};

const withHover = <P extends WithHoverInjectedPropsType>(
  WrappedComponent: React.ComponentType<P>
) => {
  const WrapperComponent: React.FunctionComponent<
    Omit<P & WithHoverWrappedProps, keyof WithHoverInjectedPropsType>
  > = (props) => {
    const { onHoverIn, onHoverOut, ...otherProps } = props;
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
      if (hover.enabled && !hovered) {
        onHoverIn();
        setHovered(true);
      }
    };

    const handleMouseLeave = () => {
      if (hovered) {
        onHoverOut();
        setHovered(false);
      }
    };

    return (
      <WrappedComponent
        {...(otherProps as P)}
        hovered={hovered}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    );
  };

  WrapperComponent.defaultProps = {
    onHoverIn: noop,
    onHoverOut: noop,
  } as any;

  return WrapperComponent;
};

export default withHover;
